import pluralize from "pluralize";

const camelizedString = (str) => {
    str = str.charAt(0).toLowerCase() + str.slice(1);
    return str.replace(/\W+(.)/g, function (match, chr) {
      return chr.toUpperCase();
    });
}

const plurarizeStr = str => {
    return pluralize.plural(str);
}

const jsUcfirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const formatPropType = str => {
    return jsUcfirst(plurarizeStr(camelizedString(str))) 
}

function stripHtmlTags(html) {
    if(html) {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    }
    return "";
  }
  
function formatBytes(bytes) {
    bytes = +bytes
    if (bytes === 0) return '0 Bytes';
    
    const sizes = ['Bytes', 'KB', 'MB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    
    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
  }
  
export { formatPropType , stripHtmlTags , formatBytes};