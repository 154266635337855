import React, { forwardRef, useEffect, useImperativeHandle, useState ,useCallback, useRef} from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fetchMethodRequest from '../../../config/service';
import { NavLink, useNavigate } from 'react-router-dom';
import { faShareAlt} from '@fortawesome/free-solid-svg-icons';
import { faThumbtack } from '@fortawesome/free-solid-svg-icons';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import configMessages from '../../../config/configMessages';
import apiCalls from '../../../config/apiCalls';

const MenuBar = forwardRef(({ setMessages, chatId , searchType ,defaultSearchFilter , type , shareChats},ref) => {
  const [isOpen, setIsOpen] = useState(true);
  const [chats ,setChats] = useState([])
  let baseFilter = {"page":1,"limit":20,"sortfield":"pinnedDate","direction" : "desc","secondorySorting":[{"field":"created","direction":"desc"}]};
  const navigate = useNavigate();
  let filterRef = useRef(baseFilter)
  let chatsRef = useRef([])
  const [totalCount,setTotalCount] = useState(0)
  let previousScrollHeightRef = useRef()
  let scrollableRef = useRef();
  const isThrottled = useRef(false);
  const [activeMenu , setActiveMenu] = useState('');
  const [openUpward,setOpenUpward] = useState()
  const menuRef = useRef(null)


  useImperativeHandle(ref, () => ({
    createChat(newChat, details) {
      setChats([])
      chatsRef.current = [];
      filterRef.current = baseFilter;
      getChats(newChat,null, details)
    },
    getSharedChats() {
      setChats([])
      chatsRef.current = [];
      filterRef.current = baseFilter;
      handleSharedChats();
    }
  }));

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (activeMenu && menuRef.current && !menuRef.current.contains(event.target)) {
        setTimeout(() => {
          setActiveMenu('');
        }, 100);
      }
    };
  
    const adjustMenuPosition = () => {
      if (activeMenu && menuRef.current) {
        const menuRect = menuRef.current.getBoundingClientRect();
        const menuHeight = menuRef.current.offsetHeight;
        const windowHeight = window.innerHeight;
        const spaceBelow = windowHeight - menuRect.bottom;
        const spaceAbove = menuRect.top;
        if (spaceBelow < menuHeight && spaceAbove > menuHeight) {
          setOpenUpward('chat-top');
        } else {
          setOpenUpward('chat-bottom');
        }
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    adjustMenuPosition();
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [activeMenu, menuRef]);

  const getChats = (newChat = false , defaultFilter = null, details) => {
    let finalFilter = defaultFilter ? defaultFilter : filterRef.current;
    if(defaultSearchFilter) {
        finalFilter.criteria = [defaultSearchFilter]
    }
    fetchMethodRequest('GET',`${apiCalls.chats}?filter=${JSON.stringify(finalFilter)}`).then((resp) => {
      if(resp.chats && resp.chats?.length > 0){
        if(resp?.pagination?.page) {
          let obj = Object.assign(finalFilter, {"page" : resp?.pagination?.page})
          finalFilter.page = resp?.pagination?.page
          filterRef.current = obj
        }
        if(resp?.pagination?.totalCount){
          setTotalCount(resp?.pagination?.totalCount)
        }
        if (resp && resp.chats) {
          if(finalFilter.page === 1) {
            setChats(resp.chats);
            chatsRef.current = resp.chats
          }
          else {
            chatsRef.current = [...chatsRef.current,...resp.chats];
            previousScrollHeightRef.current = scrollableRef.current.scrollHeight
            setChats(chatsRef.current);
            scrollToCurrentHeight()
          }
          if(newChat){
            if(details?.messages?.chatId) {
              navigate(`/${searchType}/${details.messages.chatId}` , {state : { chat : {...details?.messages, conversationType : details?.conversationType}}})
            } else {
              navigate(`/${searchType}/${resp.chats[0]?._id}`)
            }
          }
        }
      }
    })
  }

  const handleSharedChats = (defaultFilter) => {
    let finalFilter = defaultFilter ? defaultFilter : filterRef.current;
    if(finalFilter.criteria?.length > 0) {
      finalFilter.criteria = [];
    }
    fetchMethodRequest('GET',`${apiCalls.sharedChats}?filter=${JSON.stringify(finalFilter)}`).then((resp) => {
      if(resp.chats && resp.chats?.length > 0){
        if(resp?.pagination?.page) {
          let obj = Object.assign(finalFilter, {"page" : resp?.pagination?.page})
          finalFilter.page = resp?.pagination?.page
          filterRef.current = obj
        }
        if(resp?.pagination?.totalCount){
          setTotalCount(resp?.pagination?.totalCount)
        }
        if (resp && resp.chats) {
          if(finalFilter?.page === 1) {
            setChats(resp.chats);
            chatsRef.current = resp.chats
          }
          else {
            chatsRef.current = [...chatsRef.current,...resp.chats];
            previousScrollHeightRef.current = scrollableRef.current.scrollHeight
            setChats(chatsRef.current);
            scrollToCurrentHeight()
          }
        }
      }
    })
  }

  useEffect(() => {
    if(type === 'shared') {
        handleSharedChats();
    } else {
      getChats()
    }
  },[])

  const navigateToNewChat = () => {
    setMessages([])
  }

  const scrollToCurrentHeight = () => {
    if(scrollableRef.current) {
      scrollableRef.current.scrollTop =  previousScrollHeightRef.current - scrollableRef.current.scrollHeight ;
    }
  }

  const onScrollTop = (e) => {
    if (isThrottled.current) {
      return;
    }
    const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 1;
    if(bottom && (chats?.length < totalCount)) {
      let currentFilter = JSON.parse(JSON.stringify(filterRef.current))
      currentFilter.page = currentFilter.page + 1;
      if(type) {
        handleSharedChats(currentFilter)
      } else {
        getChats(false,currentFilter);
      }  
      isThrottled.current = true;
      setTimeout(() => {
        isThrottled.current = false;
      }, 300);
    }
  }

  const updateChat = async (body , url , method) => {
    return fetchMethodRequest(method , url , body).then((res) => {
      if( res && res.respMessage) {
        showToasterMessage(res.respMessage , 'success')
        filterRef.current = baseFilter;
        setChats([]);
        chatsRef.current = [];
        setActiveMenu('');
        if (type === 'shared') {
          handleSharedChats();
        } else {
          getChats();
        }
      } else if (res && res.errorMessage) {
        showToasterMessage(res.respMessage , 'error');
        setActiveMenu('');
      }
    })
  }

  const pinChat = (item) => {
    if (item && (item._id || item.sharedChatId)) {
      let body = {
        pinned: !item?.pinned
      }
      let url = `${apiCalls.chats}/${item._id}`
      if (type === 'shared') {
        url = `${apiCalls.sharedChatUpdate}/${item.sharedChatId}`
      }
      updateChat(body, url, 'PUT')
    } else {
      showToasterMessage(configMessages.chatNotFound, 'error');
    }
  }

  // for future implementation

  const renameChat = (chat) => {

  }

  const deleteChat = (chat) => {

  }

   // Toggle menu visibility
   const handleMenuClick = (chatId) => {
    setActiveMenu(activeMenu === chatId ? null : chatId); // Show or hide menu
  };

  return (
    <div className='col-sm-3 d-flex flex-column'>
    <div className='d-flex justify-content-between menu-buttons mt-1'>
            <NavLink to={'/'+searchType} onClick={navigateToNewChat}>
                <button className={!type ? 'new-chat-button active-button' : 'new-chat-button' } title='New Chat'>
                  <FontAwesomeIcon icon="plus" title='New Chat' />
                  <span className='ml-2'>New Chat</span>
                </button> 
            </NavLink>
            { shareChats && <NavLink to={'/'+searchType+'?type=shared'}>
                <button className={type ? 'new-chat-button active-button' : 'new-chat-button' } title='Shared Chats'>
                  <FontAwesomeIcon icon={faShareAlt} title='Shared Chats' />
                  <span className='ml-2'>Shared Chats</span>
                </button> 
            </NavLink>}
       </div>
    <div className="menu-bar chat-menubar" style={{ display: isOpen ? 'block' : 'none' }} onScroll={onScrollTop}>
      <ul className="message-list" ref={scrollableRef}>
        {
          chats && chats.length > 0 && chats.map((chat, index) => (
            <NavLink to={type ? `/${searchType}/${chat._id}?type=${type}` : `/${searchType}/${chat._id}`} state={{chat}}>
              <li key={index}  className={(chatId === chat._id ? 'active-chat' : '' )}>
                  <div className='d-flex justify-content-between'>
                  <div className='chat-name' title={chat.question}>{chat.question}</div> 
                  {chat?.pinned && <div className='me-2'> <FontAwesomeIcon icon={faThumbtack}/></div>}
                  <div className='active-chat-options' onClick={() => handleMenuClick(chat._id)} > <FontAwesomeIcon icon="ellipsis-h" /></div>
                  </div>
                  {activeMenu === chat._id && (
                      <ul className={"dropdown-menu show d-flex flex-column justify-content-start chat-options-menu " + openUpward } ref={menuRef} aria-labelledby={`navbarDropdown-${chat._id}`}>
                         <li className='dropdown-item'
                          title='Pin Chat'
                          onClick={() => {
                            pinChat(chat);
                          }}
                        >
                        <FontAwesomeIcon
                          className='genderIconAlignment getcursor default-icon-color width-15 margin-right-9'
                          color='white'
                          icon={faThumbtack}
                          data-toggle='tool-tip'
                        />
                        {chat?.pinned ? 'UnPin Chat' : 'Pin Chat'}
                        </li>
                        {/* For Future Implementation  */}
                        {/* <li className='dropdown-item'
                          title='Rename'
                          onClick={() => {
                            renameChat(item, 'edit', !item.type ? 'folders' : 'files');
                          }}
                        >
                        <FontAwesomeIcon
                          className='genderIconAlignment getcursor default-icon-color width-15 margin-right-9'
                          color='white'
                          icon='pen'
                          data-toggle='tool-tip'
                        />
                        Rename
                        </li>
                         <li className='dropdown-item'
                          title='Delete'
                          onClick={() => deleteChat(item, 'Delete', item.type)}
                        >
                          <FontAwesomeIcon
                          className='genderIconAlignment getcursor me-2'
                          color='white'
                          icon='trash'
                          data-toggle='tool-tip'
                          style={{
                            color: '#bf1725',
                            width: '13',
                          }}
                        />
                        Delete
                        </li> */}
                  </ul>
                    )}
              </li>
            </NavLink>
          ))
        }
      </ul>
    </div>
    </div>
  );
});

MenuBar.propTypes = {
  messages: PropTypes.array.isRequired,
  onSelectMessage: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onNewChat: PropTypes.func.isRequired,
};

export default MenuBar;
