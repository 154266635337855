
const configMessages = {
    invalidEmail: 'Please enter valid email address',
    email: 'Please enter valid email address',
    userName: 'Please enter valid name',
    roomId: 'Please enter min 4 digits and maximum 10 digits valid  RoomId',
    name: 'Please enter valid name',
    fillField: 'Please fill above field',
    phoneNumber: 'please enter valid phone number',
    InvalidDate: 'Invalid Date',
    noRecords: 'No Records Found',
    toDateBeforeFromDate: 'To date must be after from Date',
    fromAndToRangeMustBeDifferent: 'From range and To range must be different',
    noOfDaysValidation: 'No of days between From range and To range must be same',
    toRangeDateValidation: 'To Range must be after From Range dates',
    shiftErrMsg: 'Please Select Location First',
    shiftAddOnErrMsg: 'Please Select Primary Shift First',
    toDoctorErrMsg: 'Please Select From User First',
    noOptionsMessage: 'No Options Available',
    passwordMatchValidation: 'Password does not match',
    toTimeFromTimeValidation: 'To Time must be after From Time ',
    deafultErrorMessage : 'Something went wrong',
    defaultDragMessage:'Drop the files to upload them into My Documents',
    dragExtenction:'Drop the files to upload them into ',
    shareWarningMessage : "This chat has been shared before. Are you sure you want to share it again with the updated changes?",
    shareChatAlertMessage : "Are you sure you want to share this?",
    detailsNotFound : 'File Details Not Found.',
    chatNotFound : "Chat Details Not Found"

};
export default configMessages;
